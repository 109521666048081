import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Layout as AntLayout, Affix } from "antd";
import { ReactNode } from "react";

import stylesheet from "./style.module.css";

import { Brand } from "~/src/components/brand";
import { Container } from "~/src/components/container";
import { Flex } from "~/src/components/flex";
import { HelpLink } from "~/src/components/help-link";
import { useRouterScrollToTop } from "~/src/lib/use-router-scroll-to-top";
import { useSetFullHeight } from "~/src/lib/use-set-full-height";

type LayoutProps = {
  children?: ReactNode;
  extraHeaderContent?: ReactNode;
  helpButton?: boolean;
};

export const Layout = ({
  children,
  extraHeaderContent,
  helpButton = true,
}: LayoutProps) => {
  const elementRef = useSetFullHeight<HTMLDivElement>();

  return (
    <div ref={elementRef}>
      <AntLayout className={stylesheet.layout}>
        <Header extra={extraHeaderContent} />
        <Content>{children}</Content>
        <Footer helpButton={helpButton} />
      </AntLayout>
    </div>
  );
};

type HeaderProps = {
  extra?: ReactNode;
};

const Header = ({ extra }: HeaderProps) => {
  return (
    <AntLayout.Header className={stylesheet.header}>
      <Container width="lg">
        <Flex justify="space-between">
          <Brand href="/" collapsed />
          {extra}
        </Flex>
      </Container>
    </AntLayout.Header>
  );
};

type ContentProps = {
  children?: ReactNode;
};

const Content = ({ children }: ContentProps) => {
  const elementRef = useRouterScrollToTop<HTMLDivElement>();

  return <AntLayout.Content ref={elementRef}>{children}</AntLayout.Content>;
};

type FooterProps = {
  helpButton?: boolean;
};

const Footer = ({ helpButton }: FooterProps) => {
  if (!helpButton) {
    return (
      <AntLayout.Footer>
        <Flex justify="center">
          <Brand href="/" />
        </Flex>
      </AntLayout.Footer>
    );
  }

  return (
    <AntLayout.Footer className={stylesheet.footer}>
      <Container width="lg">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
          }}
        >
          <Affix offsetBottom={12}>
            <HelpLink>
              <Button
                type="primary"
                size="large"
                icon={<QuestionCircleOutlined />}
              />
            </HelpLink>
          </Affix>

          <Brand href="/" />
        </div>
      </Container>
    </AntLayout.Footer>
  );
};
