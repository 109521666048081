import { useEffect, useState } from "react";

/**
 * This component disables CSS transitions on first load so that
 * we don't see it transitioning from browser's style to our style.
 */
export const CSSTransitionFix = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return null;
  }

  return <style>{"*{transition-duration: 0s !important;}"}</style>;
};
