import { withLayout } from "@moxy/next-layout";
import { Typography } from "antd";
import { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { Box } from "~/src/components/box";
import { Container } from "~/src/components/container";
import { Head } from "~/src/components/head";
import { Translation } from "~/src/components/translation";
import { Layout } from "~/src/layouts/public";

const i18nNamespaces = ["pages/help", "common", "validation"];

const Page: NextPage = () => {
  const { t } = useTranslation(i18nNamespaces);

  return (
    <Container width="sm">
      <Head title={t("pages/help:title")} />
      <Box paddingVertical="xl" paddingHorizontal="md">
        <Typography.Title level={2}>{t("pages/help:title")}</Typography.Title>
        <Typography.Paragraph>
          <Translation
            i18nKey="pages/help:description"
            components={{ a: <a href="mailto:hi@bluy.com" /> }}
          />
        </Typography.Paragraph>
      </Box>
    </Container>
  );
};

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, i18nNamespaces)),
  },
});

export default withLayout(<Layout helpButton={false} />)(Page);
