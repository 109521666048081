import c from "classnames";
import {
  CSSProperties,
  forwardRef,
  HTMLProps,
  ReactNode,
  RefObject,
} from "react";

import stylesheet from "./style.module.css";

import { getMergedProps } from "~/src/lib/get-merged-props";

export type Spacing = "xs" | "sm" | "md" | "lg" | "xl";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  padding?: Spacing;
  paddingTop?: Spacing;
  paddingRight?: Spacing;
  paddingBottom?: Spacing;
  paddingLeft?: Spacing;
  paddingHorizontal?: Spacing;
  paddingVertical?: Spacing;
};

const paddingProps = {
  padding: "padding",
  paddingTop: "padding-top",
  paddingRight: "padding-right",
  paddingBottom: "padding-bottom",
  paddingLeft: "padding-left",
  paddingHorizontal: "padding-horizontal",
  paddingVertical: "padding-vertical",
};

const isPaddingProp = (key: string) =>
  Object.keys(paddingProps).some((k) => k === key);

/**
 * Get the HTML props that should be applied given a set of padding props.
 * @param props
 */
const getPaddingProps = (props: Props) => {
  return {
    className: c(
      Object.keys(props)
        .filter(isPaddingProp)
        .map((key) => stylesheet[`${paddingProps[key]}-${props[key]}`]),
    ),
  };
};

export const Box = forwardRef(
  ({ children, ...props }: Props, ref: RefObject<HTMLDivElement>) => {
    const { style, className } = getMergedProps<HTMLProps<HTMLDivElement>>(
      {
        className: c(stylesheet.box, props.className),
        style: props.style,
      },
      getPaddingProps(props),
    );

    return (
      <div style={style} className={className} ref={ref}>
        {children}
      </div>
    );
  },
);

Box.displayName = "Box";
