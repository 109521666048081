import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

/**
 * In some of our layouts, the content is a scrollable box, that is a div with a
 * limited size that makes the main content not on the main page scroll. Because
 * of this the built-in option of nextjs to scroll to the top of the page when
 * changing route does not work.
 * So we use this hook to apply this behavior when we need.
 * @todo This behavior will need to have an option to turn it off, because there
 * are occasions when we want to turn off this scroll behavior.
 * @see https://github.com/vercel/next.js/issues/32046
 */
export const useRouterScrollToTop = <T extends HTMLElement>() => {
  const elementRef = useRef<T>(null);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      elementRef.current?.scroll({ top: 0 });
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [elementRef, router]);

  return elementRef;
};
