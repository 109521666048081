import c from "classnames";
import Link from "next/link";
import { CSSProperties } from "react";

import stylesheet from "./style.module.css";

type VectorProps = {
  fill?: CSSProperties["fill"];
};

const B = ({ fill }: VectorProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 1024 1024"
    aria-label="B for Bluy"
  >
    <path
      fill={fill ?? "#A83BF9"}
      d="M886.527 1024a106.262 106.262 0 0 1-106.264-106.264 106.262 106.262 0 0 1 31.106-75.158 106.679 106.679 0 0 1 31.396-21.736 106.26 106.26 0 0 1 127.736 31.943 106.276 106.276 0 0 1 19.282 89.515 106.284 106.284 0 0 1-57.485 71.28 106.32 106.32 0 0 1-45.771 10.42Z"
    />
    <path
      fill={fill ?? "#0635DA"}
      d="M643.468 422.979c-14.491-12.461-91.774-74.771-198.279-75.012h-.58a307.287 307.287 0 0 0-92.353 14.49 298.626 298.626 0 0 0-92.981 50.041l-15.747 12.558V106.264A106.262 106.262 0 0 0 137.264 0 106.264 106.264 0 0 0 31 106.264v811.472a106.263 106.263 0 0 0 24.483 67.803 106.296 106.296 0 0 0 62.119 36.581c24.336 4.58 49.508.52 71.168-11.49a106.236 106.236 0 0 0 47.465-54.253l4.83-12.655 10.336 8.791A299.456 299.456 0 0 0 445.189 1024c104.863 0 181.856-61.054 196.202-73.274 68.54-58.639 104.96-143.843 108.196-253.44 3.284-116.408-33.377-211.176-106.119-274.307Zm-256 427.327c-76.559 0-145.968-58.494-145.968-164.758 0-106.264 69.409-164.757 145.968-164.757 77.814 0 147.224 57.334 147.224 163.55 0 106.216-69.603 165.772-147.417 165.772l.193.193Z"
    />
  </svg>
);

const Bluy = ({ fill }: VectorProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 1024 532"
    aria-label="Bluy"
  >
    <path
      fill={fill ?? "#0635DA"}
      d="M349.402 396.559a41.15 41.15 0 0 1-41.152-41.153V41.302A41.15 41.15 0 0 1 349.402.15a41.153 41.153 0 0 1 41.152 41.152v314.104a41.152 41.152 0 0 1-41.152 41.153ZM641.228 396.559a41.32 41.32 0 0 1-37.243-23.663l-2.039-4.396-3.741 3.087a122.488 122.488 0 0 1-4.134 3.236 108.493 108.493 0 0 1-173.588-86.757V175.833a41.152 41.152 0 0 1 41.152-41.153c26.394 0 40.61 26.487 41.153 41.302v109.952a48.639 48.639 0 0 0 14.244 34.39 48.639 48.639 0 0 0 68.78 0 48.636 48.636 0 0 0 14.245-34.39V175.833a41.152 41.152 0 1 1 82.304 0v179.573c.017 4.667-.781 9.3-2.356 13.693a41.164 41.164 0 0 1-15.025 19.892 41.164 41.164 0 0 1-23.752 7.568ZM237.15 163.786c-5.612-4.845-35.541-28.956-76.806-29.05h-.224a118.268 118.268 0 0 0-35.746 5.612 115.764 115.764 0 0 0-36.009 19.36l-6.06 4.901V41.152a41.152 41.152 0 1 0-82.305 0v314.254a41.15 41.15 0 0 0 33.536 40.375 41.146 41.146 0 0 0 45.925-25.41l1.87-4.882 4.004 3.385a115.975 115.975 0 0 0 75.028 27.685c40.61 0 70.427-23.644 75.982-28.377 26.525-22.708 40.629-55.705 41.901-98.167 1.272-45.08-12.945-81.799-41.096-106.229Zm-99.14 165.489c-29.667 0-56.547-22.653-56.547-63.805s26.88-63.805 56.547-63.805c30.116 0 56.996 22.185 56.996 63.337 0 41.153-26.955 64.217-57.071 64.217l.075.056ZM983.26 193.21 849.721 479.518l-13.094 27.909a41.165 41.165 0 0 1-39.098 23.721 41.165 41.165 0 0 1-36.877-27.044 41.15 41.15 0 0 1 1.377-31.47l42.2-90.497-88.141-188.927a41.152 41.152 0 1 1 74.579-34.774l58.96 126.432 59.035-126.432a41.15 41.15 0 1 1 74.598 34.774Z"
    />
    <path
      fill={fill ?? "#A83BF9"}
      d="M984.063 396.615c22.057 0 39.937-17.88 39.937-39.937 0-22.056-17.88-39.936-39.937-39.936-22.056 0-39.936 17.88-39.936 39.936 0 22.057 17.88 39.937 39.936 39.937Z"
    />
  </svg>
);

type Props = {
  collapsed?: boolean;
  color?: "default" | "white";
  size?: "large" | "middle";
  href?: string;
};

export const Brand = ({
  collapsed = false,
  color = "default",
  size = "middle",
  href,
}: Props) => {
  const Vector = collapsed ? B : Bluy;
  const fill = color === "white" ? "#fff" : undefined;

  const className = [stylesheet.brand];
  if (collapsed) {
    className.push(stylesheet.collapsed);
  }
  className.push(stylesheet[size]);

  if (href) {
    return (
      <Link href={href} className={c(className)}>
        <Vector fill={fill} />
      </Link>
    );
  }

  return (
    <span className={c(className)}>
      <Vector fill={fill} />
    </span>
  );
};
