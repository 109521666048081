import NextHead from "next/head"; // eslint-disable-line no-restricted-imports
import { ReactNode } from "react";

import { CSSTransitionFix } from "~/src/components/css-transition-fix";

const areRobotsAllowed = process.env.NEXT_PUBLIC_ALLOW_ROBOTS === "true";

type Props = {
  title?: string;
  children?: ReactNode;
};

/**
 * Head of the document, for title, meta tags, etc.
 */
export const Head = ({ title, children }: Props) => {
  return (
    <NextHead>
      <title>{title ? `${title}—Bluy` : "Bluy"}</title>
      {areRobotsAllowed ? null : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {children}
      <CSSTransitionFix />
    </NextHead>
  );
};
