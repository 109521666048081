import c from "classnames";
import { CSSProperties, HTMLProps, ReactNode } from "react";

import stylesheet from "./style.module.css";

import { getMergedProps } from "~/src/lib/get-merged-props";

export type ContainerProps = {
  id?: string;
  className?: string;
  style?: CSSProperties;
  width?: "xs" | "sm" | "md" | "lg" | "xl" | number;
  children: ReactNode;
};

const mapWidthProps = (value: ContainerProps["width"]) => {
  if (typeof value === "string") {
    return { className: stylesheet[value] };
  } else if (typeof value === "number") {
    return { style: { width: `${value}px` } };
  }
  return {};
};

/**
 * Container limits the width of its content and adds auto margins on both sides.
 */
export const Container = ({
  id,
  className,
  style,
  width = "md",
  children,
}: ContainerProps) => {
  const props = getMergedProps<HTMLProps<HTMLDivElement>>(
    { className: c(stylesheet.container, className), style },
    mapWidthProps(width),
  );
  return (
    <div id={id} {...props}>
      {children}
    </div>
  );
};
